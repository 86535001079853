import React, {useContext, useEffect, useState} from 'react';
import {
    Button, ButtonGroup, Col,
    Form,
    FormGroup,
    Input, Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader, Row
} from "reactstrap";
import {AppContext} from "../../../App";
import axios from "../../utils/axiosInstance";

const AddEditModel = (props) => {

    const [isExistingCustomer, setIsExistingCustomer] = useState(false);
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [isCandleClose, setIsCandleClose] = useState(false);
    const {lkValues} = useContext(AppContext);
    const {servers, models, exchanges, tradeBots, stopLossTypes} = lkValues;
    const [profileInfo, setProfileInfo] = useState({});
    const [updatedProfileInfo, setUpdatedProfileInfo] = useState();
    const [users, setUsers] = useState([]);

    useEffect(() => {
        setIsExistingCustomer(false);
        fetchUsers();
    }, [])

    useEffect(() => {
        if (props.data) {
            const profile = {};
            if (props.data.exchange) profile.exchangeId = props.data.exchange.exchangeId;
            if (props.data.model) profile.modelId = props.data.model.modelId;
            if (props.data.server) profile.serverId = props.data.server.serverId;
            if (props.data.tradeBot) profile.tradeBotId = props.data.tradeBot.tradeBotId;
            if (props.data.stopLoss) profile.stopLossId = props.data.stopLoss.stopLossId;
            if (props.data.user) {
                profile.customerName = `${props.data.user.firstName} ${props.data.user.lastName}`;
                profile.userId = props.data.user.userId;
            }
            profile.portfolioId = props.data.portfolioId;
            profile.portfolioName = props.data.portfolioName;
            profile.sellThreshold = props.data.sellThreshold;
            profile.buyThreshold = props.data.buyThreshold;
            profile.candleClose = props.data.candleClose;
            profile.readOnly = props.data.readOnly;
            setIsReadOnly(props.data.readOnly);
            setIsCandleClose(props.data.candleClose);
            setProfileInfo({...profile});
            setUpdatedProfileInfo({
                portfolioPk: props.data.portfolioPk,
                onBoarding: props.isAdd
            });
        }
    }, [props.data])

    const fetchUsers = () => {
        axios.get("/api/getUsers").then(res => {
            if (res && res.data) {
                res.data.forEach(user => user.customerName = `${user.firstName}  ${user.lastName}`)
                setUsers(res.data);
            }
        }).catch(ex => {
            console.log("failed to load users with Exception: ", ex);
        })
    }

    const toggleIsExistingCustomer = (id, value) => {
        setIsExistingCustomer(value);
        onChange('newCustomer', !value);
    }

    const toggleReadOnly = (id, value) => {
        setIsReadOnly(value);
        onChange('readOnly', value);
    }

    const toggleCandleClose = (id, value) => {
        setIsCandleClose(value);
        onChange('candleClose', value);
    }

    const onSubmit = (event) => {
        if (Object.keys(updatedProfileInfo).length > 0 && profileInfo !== updatedProfileInfo) {
            props.onSubmit({...updatedProfileInfo});
        } else props.onToggle();
    }

    const onDelete = (event) => {
        if (window.confirm("Are you sure you want to delete this portfolio ?")) {
            props.onSubmit({...updatedProfileInfo, ...{delete: true}})
        }
    }

    const onChange = (name, value) => {
        if (profileInfo[name] !== value) {
            const updatedData = {...updatedProfileInfo}
            updatedData[name] = value;
            setUpdatedProfileInfo(updatedData);
        }
    }

    return (
        <Modal isOpen={props.isOpen} size="lg" toggle={props.onToggle} style={{fontSize: "12px"}}>
            <ModalHeader toggle={props.onToggle}> {props.isAdd ? "Add" : "Edit"} Portfolio </ModalHeader>
            <ModalBody>
                <Form className="m-3" onSubmit={onSubmit}>
                    {!props.isAdd &&
                    <InputComponent name="Customer Name" defaultValue={profileInfo.customerName} readOnly={true}/>
                    }
                    {props.isAdd &&
                    <>
                        <RadioComponent name="Is Existing Customer" currentValue={isExistingCustomer}
                                        onChange={toggleIsExistingCustomer}/>
                        {isExistingCustomer &&
                        <DropDownComponent name="Customer Name" defaultValue={profileInfo.tradeBotId}
                                           dropDownValues={users} attrs={{id: 'userId', name: 'customerName'}}
                                           id="userId" onChange={onChange}/>
                        }
                        {!isExistingCustomer &&
                        <>
                            <InputComponent name="First Name" id="firstName" onChange={onChange}/>
                            <InputComponent name="Last Name" id="lastName" onChange={onChange}/>
                        </>
                        }
                        <InputComponent name="Public Key" id="publicKey" type="password" onChange={onChange}/>
                        <InputComponent name="Pass Phrase" id="passPhrase" type="password" onChange={onChange}/>
                        <InputComponent name="Secret" id="secret" type="password" onChange={onChange}/>
                    </>
                    }
                    <InputComponent name="Portfolio Id" defaultValue={profileInfo.portfolioId} readOnly={!props.isAdd}
                                    id="portfolioId" onChange={onChange}/>
                    <InputComponent name="Portfolio Name" defaultValue={profileInfo.portfolioName}
                                    id="portfolioName" onChange={onChange}/>
                    <DropDownComponent name="Model" defaultValue={profileInfo.modelId}
                                       dropDownValues={models} attrs={{id: 'modelId', name: 'modelName'}} id="modelId"
                                       onChange={onChange}/>
                    <DropDownComponent name="Trade Bot" defaultValue={profileInfo.tradeBotId}
                                       dropDownValues={tradeBots} attrs={{id: 'tradeBotId', name: 'tradeBotName'}}
                                       id="tradeBotId" onChange={onChange}/>
                    <DropDownComponent name="Exchange" defaultValue={profileInfo.exchangeId}
                                       dropDownValues={exchanges} attrs={{id: 'exchangeId', name: 'exchangeName'}}
                                       id="exchangeId" onChange={onChange}/>
                    <InputComponent name="Buy Threshold" defaultValue={profileInfo.buyThreshold} type="number"
                                    id="buyThreshold" onChange={onChange}/>
                    <InputComponent name="Sell Threshold" defaultValue={profileInfo.sellThreshold} type="number"
                                    id="sellThreshold" onChange={onChange}/>
                    <DropDownComponent name="Server" defaultValue={profileInfo.serverId}
                                       dropDownValues={servers} attrs={{id: 'serverId', name: 'serverName'}}
                                       id="serverId" onChange={onChange}/>
                    <DropDownComponent name="Stop Loss" defaultValue={profileInfo.stopLossId}
                                       dropDownValues={stopLossTypes} attrs={{id: 'stopLossId', name: 'stopLossType'}}
                                       id="stopLossId" onChange={onChange}/>
                    <RadioComponent name="Candle Close" currentValue={isCandleClose}
                                    id="candleClose" onChange={toggleCandleClose}/>
                    <RadioComponent name="Read Only" currentValue={isReadOnly}
                                    id="readOnly" onChange={toggleReadOnly}/>

                    {!props.isAdd &&
                    <Button color="danger" className="w-100 text-center" onClick={onDelete}> Delete Portfolio </Button>
                    }
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button onClick={props.onToggle}> Cancel </Button>
                <Button color="primary" onClick={onSubmit}> Submit </Button>
            </ModalFooter>
        </Modal>
    );
};


const DropDownComponent = ({id, name, defaultValue, dropDownValues, attrs, className, style, onChange}) => {
    return (
        <FormGroup row key={name} className={className} style={style}>
            <Label sm={4}> {name} </Label>
            <Col>
                <Input name="select" type="select" defaultValue={defaultValue}
                       onChange={(event) => onChange(id, event.target.value)}>
                    <option selected disabled>Select</option>
                    {dropDownValues.map(value =>
                        <option key={value[attrs.id]} value={value[attrs.id]}> {value[attrs.name]}</option>)
                    }
                </Input>
            </Col>
        </FormGroup>
    );
}

const InputComponent = ({id, name, defaultValue, type = "text", readOnly = false, className, style, onChange}) => {
    return (
        <FormGroup row className={className} style={style}>
            <Label sm={4}> {name} </Label>
            <Col>
                <Input readOnly={readOnly} defaultValue={defaultValue} type={type}
                       onChange={(event) => onChange(id, event.target.value)}/>
            </Col>
        </FormGroup>
    )
}

const RadioComponent = ({id, name, currentValue, onChange}) => {
    return (
        <FormGroup row>
            <Label sm={4}> {name} </Label>
            <Col>
                <ButtonGroup>
                    <Button outline={!currentValue} color={currentValue ? 'primary' : 'secondary'}
                            className='px-4 no-focus' onClick={(event) => onChange(id, true)}>
                        Yes
                    </Button>
                    <Button outline={currentValue} color={!currentValue ? 'primary' : 'secondary'}
                            className='px-4 no-focus' onClick={(event) => onChange(id, false)}>
                        No
                    </Button>
                </ButtonGroup>
            </Col>
        </FormGroup>
    )
}

export default AddEditModel;