import React, {useContext, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {AppContext} from "../../App";
import {Button} from "reactstrap";

const Header = (props) => {

    let navigate = useNavigate();
    const {loginInfo,setLoginInfo} = useContext(AppContext);
    console.log("login info");
    console.log(loginInfo)
    //
    // useEffect(() => {
    //     if (loginInfo.isLoggedIn) {
    //         fetchLookUpValues();
    //     }
    // }, [loginInfo])

    const navigateHome = () => {
        navigate("/")
    }

    const logout=()=>{
        setLoginInfo({});
        navigateHome();
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-light pt-0" style={{backgroundColor: "#5f6e45"}}>
            <div className="container-fluid">
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <span className="navbar-brand anchor-style" onClick={navigateHome}>
                        <img
                            src="TraderyCapitalLogo.png"
                            height="30"
                        />
                    </span>
                </div>

                {loginInfo.isLoggedIn &&
                <>
                    <div className="d-flex align-items-center">
                        <img
                            src={loginInfo.imageUrl}
                            className="rounded-circle"
                            height="25"
                        />
                    </div>
                    <div className="ps-3 pe-2">
                        <Button color="danger" onClick={logout}>Logout</Button>
                    </div>
                </>
                }
            </div>
        </nav>
    );
};

export default Header;