import './App.css';
import UserLogin from "./components/login/UserLogin";
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    Routes,
    Route
} from "react-router-dom";
import Header from "./components/header/Header";
import ServerGrid from "./components/servers/grid/ServerGrid";
import {createContext, useEffect, useState} from "react";
import Servers from "./components/servers/Servers";
import NotFound404 from "./components/NotFound404";
import axios from "./components/utils/axiosInstance";

export const AppContext = createContext({});

export default function App() {
    const [loginInfo, setLoginInfo] = useState({});
    const [lkValues, setLkValues] = useState();

    useEffect(() => {
        if (loginInfo.isLoggedIn) {
            fetchLookUpValues();
        }
    }, [loginInfo])

    const fetchLookUpValues = () => {
        axios.get("/api/getLookupObjects").then(res => {
            if (res && res.data) setLkValues(res.data);
        }).catch(ex => {
            console.log("failed to load lookup values with Exception: ", ex);
        })
    }

    const contextValue = {loginInfo, setLoginInfo, lkValues, setLkValues};

    return (
        <div className="d-flex flex-column vh-100 vw-100">
            <AppContext.Provider value={contextValue}>
                <Header></Header>
                <div className="flex-grow-1 m-4">
                    <Routes>
                        {loginInfo.isLoggedIn &&
                        <>
                            <Route path="/" element={<Servers/>}/>
                            <Route path="servers" element={<Servers/>}/>
                            <Route path="servers/:serverId" element={<ServerGrid/>}/>
                        </>
                        }
                        <Route path="/" element={<UserLogin/>}/>
                        <Route path="*" element={<NotFound404/>}/>
                    </Routes>
                </div>
            </AppContext.Provider>
        </div>
    );
}

