import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";

function getBaseURL(){
    if(window.location && window.location.pathname !==undefined){
        const path = window.location.pathname;
        return path.substr(0,path.lastIndexOf('/'));
    }
    return "";
}

ReactDOM.render(
  <React.StrictMode>
      <BrowserRouter basename={getBaseURL()}>
          <App />
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
