import React, {useContext} from 'react';
import {Card, CardTitle, CardBody, CardText} from 'reactstrap';
import {useNavigate} from "react-router-dom";
import {AppContext} from "../../App";

export default function Servers(){
    let navigate = useNavigate();

    const {lkValues} = useContext(AppContext);

    const onCardClick = (serverId) => {
        navigate(`/servers/${serverId}`);
    }

    return (
        <div className="d-flex flex-column h-100">
            <div className={"d-flex flex-wrap justify-content-around"}>
                {lkValues && lkValues.servers && lkValues.servers.length > 0 && lkValues.servers.map(server => {
                    return <Card key={server.serverId} className={"server-card cursor-pointer m-5"}
                                 style={{flex: "0 1 400px"}}
                                 onClick={() => onCardClick(server.serverId)}>
                        <CardBody>
                            <CardTitle tag="h6"
                                       className="text-center fw-bold tradery-green"> {server.serverName} </CardTitle>
                            <CardText className="fst-italic" style={{fontSize: "10px"}}>
                                <div className="row">
                                    <div className="col-7"><span className="tradery-green fw-bold">Ip Address : </span>
                                        <span>{server.ipAddress}</span>
                                    </div>
                                    <div className="col-5"><span
                                        className="tradery-green fw-bold">Process Time :</span> {server.currentProcessTime} min
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-7"><span
                                        className="tradery-green fw-bold"> Customers :</span> {server.customers} </div>
                                    <div className="col-5"><span
                                        className="tradery-green fw-bold">Portfolios :</span> {server.portfolios} </div>
                                </div>
                            </CardText>
                        </CardBody>
                    </Card>
                })}
            </div>
        </div>
    );
};
