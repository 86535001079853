import axios from 'axios';

export function getBaseUrl() {
    if (window.location && window.location.pathname !== undefined) {
        const path = window.location.pathname;
        return window.location.origin + path.substr(0, path.lastIndexOf('/')) + "/";
    }
}

const axiosInstance = axios.create({
        baseURL: getBaseUrl(),
        responseType: 'json',
        headers: {
            withCredentials: true,
            Accept: 'application/json'
        }
    }
);

export default axiosInstance;