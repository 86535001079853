import React from 'react';
import {Link} from "react-router-dom";

const NotFound404 = () => {
    return (
        <div className="d-flex flex-column justify-content-center h-100 text-center">
            <div>Page Not Found, Click <Link className='d-inline-block' to="/">here</Link> to login</div>
        </div>
    );
};

export default NotFound404;