import React, {useContext, useEffect, useState} from 'react';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import AddEditModel from "./AddEditModel";
import {useNavigate, useParams} from "react-router-dom";
import {Button} from "reactstrap";
import axios from "../../utils/axiosInstance";
import {AppContext} from "../../../App";


let gridApi;
const ServerGrid = (props) => {
    const params = useParams();
    const [portfolios, setPortfolios] = useState([]);
    const {loginInfo} = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        getPortfolios();
    }, [])

    const getPortfolios = () => {
        axios.get(`/api/getPortfolios?serverId=${params.serverId}`).then(res => {
            if (res && res.data) {
                res.data.forEach(row => row.customerName = `${row.user.firstName} ${row.user.lastName}`)
                setPortfolios(res.data)
            }
        }).catch(err => {
            console.log(`Failed to fetch Portfolios for server with id ${params.serverId} with Error: `, err);
        })
    }

    const customerCellRenderer = (params) => {
        if (params.data) {
            return <span className="anchor-style"
                         onClick={() => onCustomerClick(params.data)}>{params.data.portfolioName}</span>
        } else return "";
    }

    const columnDefs = [
        // {
        //     field: "customerId", headerName: "Customer Id", rowGroup: true,
        // },
        {field: "customerName", headerName: "Customer Name", rowGroup: true, hide: true},
        {
            field: "portfolioName",
            headerName: "Portfolio Name", ...(loginInfo.permissions.writeAccess && {cellRenderer: customerCellRenderer})
        },
        {field: "portfolioId", headerName: "Portfolio Id"},
        {field: "model.modelName", headerName: "Model"},
        {field: "buyThreshold", headerName: "Buy Threshold"},
        {field: "sellThreshold", headerName: "Sell Threshold"},
        {field: "tradeBot.tradeBotName", headerName: "Trade Bot"},
        {field: "exchange.exchangeName", headerName: "Exchange"},
        {field: "server.serverName", headerName: "Server Name"},
        {field: "stopLoss.stopLossType", headerName: "Stop Loss"},
        {field: "readOnly", headerName: "Read Only"},
        {field: "candleClose", headerName: "Candle Close"},
    ]

    const [showModel, setShowModel] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [isAdd, setIsAdd] = useState(false);

    const onCustomerClick = (data) => {
        setIsAdd(false);
        setSelectedRow(data);
        onModelToggle();
    }
    const onGridReady = (api) => {
        gridApi = api;
        gridApi.api.sizeColumnsToFit();
    }

    const onModelToggle = () => {
        setShowModel(!showModel);
    }

    const onModelSubmit = (data) => {
        axios.post("/api/saveOrUpdatePortfolio", JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            getPortfolios();
            onModelToggle();
        });
    }

    const onAddPortfolioClick = () => {
        setIsAdd(true);
        onModelToggle();
    }

    const navigateBack = () => {
        navigate("/servers")
    }


    return (
        <div className={"d-flex flex-column h-100"}>
            <div className="py-2">
                <Button size="sm" color="primary" onClick={navigateBack}>{"<"} Back</Button>
                <Button size="sm" color="primary" className="float-end" onClick={onAddPortfolioClick}>Add
                    Portfolio</Button>
            </div>
            <div className={"flex-grow-1 ag-theme-alpine"}>
                <AgGridReact
                    rowData={portfolios}
                    onGridReady={onGridReady}
                    columnDefs={columnDefs}
                    defaultColDef={
                        {
                            filter: "agTextColumnFilter",
                            floatingFilter: true,
                            resizable: true,
                        }
                    }
                >
                </AgGridReact>
            </div>
            <AddEditModel isOpen={showModel} isAdd={isAdd} data={isAdd ? {} : selectedRow}
                          onToggle={onModelToggle}
                          onSubmit={onModelSubmit}/>
        </div>
    );
};

export default ServerGrid;