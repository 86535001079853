import React, {useContext, useState} from 'react';
import GoogleLogin from "react-google-login";
import axios from "../utils/axiosInstance";
import {AppContext} from "../../App";
import {useNavigate} from "react-router-dom";

const UserLogin = () => {
    let navigate = useNavigate();
    const [loginStatus, setLoginStatus] = useState({success:false, error:""});
    const {setLoginInfo} = useContext(AppContext);

    const navigateToServers=()=>{
        navigate("/servers");
    }

    const onGoogleLoginSuccess = (response) => {
        console.log(`process.env.WEBSERVICE_URL  -- ${process.env.WEBSERVICE_URL}`)

        axios.get(`/api/getPermissions?emailId=${response.profileObj.email}`).then(res => {
            if (res && res.data ) {
                if(res.data.readAccess || res.data.writeAccess) {
                    setLoginInfo({...response.profileObj, isLoggedIn: true, permissions:res.data});
                    navigateToServers();
                }else loginFailed(`Google User ${response.profileObj.firstName} ${response.profileObj.lastName} does not have privileges to login`);
            } else loginFailed("Google Authentication Failed");
        })
    }

    const loginFailed=(error)=>{
        setLoginInfo({isLoggedId:false});
        setLoginStatus({success: false,error:error});
    }

    const onGoogleLoginFailed = (response) => {
        console.log(response);
        loginFailed("Google Authentication Failed");
    }

    return (
        <div className={"d-flex flex-column text-center h-100 pt-5"}>
            <div className="d-inline-block pt-5">
                <div className="mb-4">
                    Please Login using Google
                </div>
                {!loginStatus.success && loginStatus.error &&
                <div className="text-danger py-1">
                    {loginStatus.error}
                </div>
                }
                <GoogleLogin
                    clientId="71491172728-pgodblet0v0l96ks5cr6avi5ia9btagn.apps.googleusercontent.com"
                    buttonText="Login"
                    onSuccess={onGoogleLoginSuccess}
                    onFailure={onGoogleLoginFailed}
                    cookiePolicy={'single_host_origin'}
                />
            </div>
        </div>
    );
};

export default UserLogin;